import { useCallback, useEffect, useState } from 'react'

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement } from '@stripe/stripe-js'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { usePrevious } from 'react-use'

import { useIntercom } from 'react-use-intercom'

import { config } from '@fairhq/common'
import { identifyIntercomUser } from 'hooks/identifyIntercomUser'
import { useErrorMessage } from 'hooks/useErrorMessage'
import { useSubmit } from 'hooks/useSubmit'
import { useCreateCompanyMutation } from 'store/company/companyApiWithQuery'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  retryInvoice,
  processPayment as processPaymentAction,
} from 'store/payment/paymentSlice'
import { Coupon, Price } from 'store/payment/types'
import { State } from 'store/state'

import { completePayment } from '../utils/completePayment'

export const useCardForm = ({
  endOnboarding,
  invoicingEnabled,
  price,
  hasCustomer,
  setFeedbackMessage,
  size,
}: {
  endOnboarding: () => void
  invoicingEnabled: boolean
  price?: Price
  size?: number
  hasCustomer: boolean
  setFeedbackMessage: (s: string) => void
}) => {
  const dispatch = useAppDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const {
    user,
    intercomUserHash,
    error,
    isIncomplete,
    customer,
    loading,
    subscription,
    paymentIntent,
    company,
  } = useAppSelector(
    (state: State) => ({
      user: state.authReducer.user,
      intercomUserHash: state.authReducer.intercom,
      error: state.paymentReducer.error,
      loading: state.paymentReducer.loading,
      isIncomplete: state.paymentReducer.isIncomplete,
      subscription: state.paymentReducer.subscription,
      customer: state.customerReducer.customer,
      paymentIntent: state.paymentReducer.paymentIntent,
      company: state.companyReducer.company,
    }),
    shallowEqual
  )
  const { update: updateIntercom } = useIntercom()
  const [createCompany, { isSuccess: isCreateCompanySuccess }] =
    useCreateCompanyMutation()
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const { clearError, setError, errorMessage } = useErrorMessage()
  const { submitting, setSubmitting } = useSubmit()
  const [fulfilling, setFulfilling] = useState(false)
  const previousLoading = usePrevious(loading)
  const hasLoaded =
    previousLoading !== loading && !loading && submitting && !fulfilling

  const onSubmit = async (coupon?: Coupon) => {
    if (!stripe || !elements) {
      return
    }
    setSubmitting(true)
    setFeedbackMessage('warning.DontRefresh')

    if (company && !company.id) {
      createCompany({ company: _.omit(company, 'sessions') })
    }

    const couponId = coupon ? coupon.id : ''
    if (invoicingEnabled) {
      processPayment('', couponId)
      return
    }

    if (coupon?.percent_off === 100) {
      processPayment('', couponId)
      return
    }

    const cardElement = elements.getElement(CardElement)
    const { error: errorPaymentMethod, paymentMethod } =
      await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement as StripeCardElement,
      })

    clearError()

    if (errorPaymentMethod) {
      setError({
        error: errorPaymentMethod,
        message: errorPaymentMethod?.message,
      })
      setSubmitting(false)
    } else {
      processPayment(paymentMethod?.id || '', couponId)
    }
  }

  const processPayment = (paymentId: string, coupon: string) => {
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      config.latestInvoicePaymentIntentStatus
    )
    setPaymentMethodId(paymentId)
    const isNotComplete =
      latestInvoicePaymentIntentStatus === 'requires_payment_method' ||
      latestInvoicePaymentIntentStatus === 'requires_action'
    const info = {
      customerId: customer?.id || '',
      paymentMethodId: paymentId,
    }
    if (isNotComplete) {
      dispatch(
        retryInvoice({
          ...info,
          invoiceId: localStorage.getItem(config.latestInvoiceId) || '',
        })
      )
    } else {
      dispatch(
        processPaymentAction({
          ...info,
          price: price?.id || '',
          subscriptionId: subscription?.id || '',
          quantity: size,
          coupon,
        })
      )
    }
  }

  const handleCompletePayment = useCallback(() => {
    completePayment({
      clearError,
      next: endOnboarding,
      paymentIntent,
      paymentMethodId,
      setError,
      setFulfilling,
      setSubmitting,
      stripe,
      t,
    }).then(() => {
      setSubmitting(false)
      setFulfilling(false)
    })
  }, [
    clearError,
    endOnboarding,
    paymentIntent,
    paymentMethodId,
    setError,
    setSubmitting,
    stripe,
    t,
  ])

  const handlePayment = useCallback(() => {
    if (error) {
      setSubmitting(false)
      setError(error)
    } else if (isIncomplete) {
      handleCompletePayment()
    } else {
      endOnboarding()
      setFeedbackMessage('warning.DontRefreshAlmostThere')
    }
  }, [
    error,
    handleCompletePayment,
    isIncomplete,
    endOnboarding,
    setError,
    setSubmitting,
    setFeedbackMessage,
  ])

  useEffect(() => {
    if (!hasCustomer) {
      return
    }
    if (hasLoaded) {
      handlePayment()
    }
  }, [handlePayment, hasLoaded, hasCustomer])

  useEffect(() => {
    if (isCreateCompanySuccess && company?.id) {
      identifyIntercomUser(
        user,
        intercomUserHash,
        company.id,
        company.name,
        company?.sector,
        updateIntercom
      )
    }
  }, [isCreateCompanySuccess, user, company, updateIntercom, intercomUserHash])

  return { errorMessage, onSubmit, submitting }
}
