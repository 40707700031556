import { FunctionComponent, useRef, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Navigate as Redirect } from 'react-router-dom'

import { Spinner } from '@fairhq/common'

import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { useGetActiveSubscriptionQuery } from '../onboardingApi'

const MAX_POLLING_SECONDS = 15

export const ActiveSubscriptionGuard: FunctionComponent = () => {
  const { t } = useTranslation()

  const sessionId = useAppSelector(
    (state: State) => state.apiHeadersReducer.sessionId
  )

  const { data: hasActiveSubscription, isError } =
    useGetActiveSubscriptionQuery({ sessionId }, { pollingInterval: 1000 })

  const seconds = useRef(0)
  useEffect(() => {
    const interval = setInterval(() => {
      seconds.current += 1
    }, 1000)
    return () => clearInterval(interval)
  }, [seconds])

  if (seconds.current > MAX_POLLING_SECONDS || isError) {
    return <Redirect to="/setup-error" />
  }

  if (hasActiveSubscription) {
    return <Redirect to="/" />
  }

  return <Spinner backdrop vertical content={t('account.setup.loading')} />
}
