import { useTranslation } from 'react-i18next'

import { useGetSurveyWindowArchiveComparisonQuery } from 'store/surveyWindow/surveyWindowApi'

export const useSidebarExperience = () => {
  const { t } = useTranslation()

  const { data: archive, isSuccess: isArchiveSuccess } =
    useGetSurveyWindowArchiveComparisonQuery()

  const latestArchiveSurveyWindow = archive?.[0]
  const EXPERIENCE_CODE = 'experience'

  if (isArchiveSuccess) {
    return {
      sidebarItems: {
        code: EXPERIENCE_CODE,
        enabled: !!latestArchiveSurveyWindow,
        text: t(`${EXPERIENCE_CODE}.title`),
        url: `/reports/${EXPERIENCE_CODE}`,
        items: undefined,
      },
    }
  }
  return { sidebarItems: undefined }
}
