import { FunctionComponent, useEffect } from 'react'

import { Grid, Heading, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import styled from 'styled-components'

import { Spinner } from '@fairhq/common'
import { useTitle } from 'features/areas/hooks/useTitle'
import { useAreas } from 'hooks/useAreas'
import { getAreas } from 'store/area/areaSlice'
import { AreaType } from 'store/area/types'
import { State } from 'store/state'
import { tCode } from 'utils/tCode'

import { Layout } from '../../layout/Layout'
import { PageTop } from '../../layout/PageTop'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { AssessmentSidebar } from './AssessmentSidebar'
import AreaCard from './components/AreaCard'

const CenteredSpinner = styled.div`
  display: flex;
  justify-content: center;
`
const Assessments: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const tArea = tCode(t)
  const { loading, areas } = useAppSelector(
    (state: State) => ({
      areas: state.areaReducer.areas || [],
      loading: state.areaReducer.loading,
    }),
    shallowEqual
  )
  const { title, percent } = useTitle()

  useAreas({})

  useEffect(() => {
    if (!loading && areas?.length === 0) {
      dispatch(getAreas({ type: AreaType.SUB }))
    }
  })

  const content = areas?.filter(
    ({ sources }) =>
      sources?.includes('company') || sources?.includes('documents')
  )

  return (
    <Layout dark sidebar={<AssessmentSidebar />}>
      <PageTop>
        <Heading>{title}</Heading>
        <Text as="div" variant="subtitle" sx={{ color: 'grey400' }}>
          {percent}%
        </Text>
      </PageTop>

      {loading && (
        <CenteredSpinner>
          <Spinner />
        </CenteredSpinner>
      )}

      {!loading && content.length > 0 && (
        <Grid columns={content.length < 5 ? 1 : 2} gap={4} sx={{ rowGap: 2 }}>
          {content.map(({ code, title: areaTitle }) => (
            <AreaCard
              key={code}
              label={tArea(code, 'title', areaTitle)}
              code={code}
            />
          ))}
        </Grid>
      )}
    </Layout>
  )
}

export default Assessments
