import { shallowEqual } from 'react-redux'

import { ApiVersion, SessionId } from 'store/apiHeaders/types'
import {
  useGetAuditCompanyQuery,
  useGetAuditDocumentsQuery,
} from 'store/audit/auditApiWithQuery'
import { useAppSelector } from 'store/hooks'

import { State } from 'store/state'

import { QueryStatus } from 'store/types'

export const useIsCompanyAssessmentComplete = (
  sessionId?: SessionId,
  apiVersion?: ApiVersion
): {
  auditQueryStatus: QueryStatus | undefined
  isCompanyAssessmentCompleted?: boolean
} => {
  const { apiHeadersSessionId, apiHeadersApiVersion } = useAppSelector(
    (state: State) => ({
      apiHeadersSessionId: state.apiHeadersReducer.sessionId,
      apiHeadersApiVersion: state.apiHeadersReducer.apiVersion,
    }),
    shallowEqual
  )

  const values = {
    sessionId: sessionId ?? apiHeadersSessionId,
    apiVersion: apiVersion ?? apiHeadersApiVersion,
  }

  const {
    data: company,
    isLoading: isCompanyLoading,
    isError: isCompanyError,
  } = useGetAuditCompanyQuery({
    sessionId: values.sessionId,
    apiVersion: values.apiVersion,
  })

  const {
    data: documents,
    isLoading: isDocumentsLoading,
    isError: isDocumentsError,
  } = useGetAuditDocumentsQuery({
    sessionId: values.sessionId,
    apiVersion: values.apiVersion,
  })

  if (isCompanyError || isDocumentsError) {
    return {
      auditQueryStatus: QueryStatus.ERROR,
      isCompanyAssessmentCompleted: undefined,
    }
  }

  if (isCompanyLoading || isDocumentsLoading) {
    return {
      auditQueryStatus: QueryStatus.LOADING,
      isCompanyAssessmentCompleted: undefined,
    }
  }

  return {
    auditQueryStatus: QueryStatus.SUCCESS,
    isCompanyAssessmentCompleted:
      (company?.percent ?? 0) === 100 && (documents?.percent ?? 0) === 100,
  }
}
