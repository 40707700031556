import React, { useState, useEffect, Dispatch, useCallback } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { shallowEqual } from 'react-redux'
import { Navigate as Redirect } from 'react-router-dom'

import { config } from '@fairhq/common'
import i18n from 'i18n'
import { updateOnboarding } from 'store/account/accountSlice'
import { authActions } from 'store/auth/authSlice'
import { CompanySector } from 'store/company/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useGetPricesQuery } from 'store/payment/paymentApiWithQuery'
import { getTax } from 'store/payment/paymentSlice'
import { Price, PriceLookupKeys } from 'store/payment/types'
import { State } from 'store/state'

import { toAmount } from 'utils/currency'

import { useCheckIfFeatureIsEnabledQuery } from '../onboardingApi'

import { CardForm } from './CardForm'

const endOnboarding = (
  dispatch: Dispatch<any>,
  user_metadata: any,
  setSubmitting: (submitting: boolean) => void
) => {
  localStorage.removeItem(config.latestInvoiceId)
  localStorage.removeItem(config.latestInvoicePaymentIntentStatus)
  dispatch(
    updateOnboarding({
      user: {
        user_metadata: {
          ...user_metadata,
          onboarding: { ...user_metadata?.onboarding, hidden: true },
        },
      },
      refreshUser: false,
    })
  )
  setSubmitting(true)
}

const PaymentFormComponent = () => {
  const dispatch = useAppDispatch()
  const { getAccessTokenSilently } = useAuth0()
  const { customer, company, tax, loading, jwt, user_metadata, saved } =
    useAppSelector(
      (state: State) => ({
        customer: state.customerReducer.customer,
        company: state.companyReducer.company,
        tax: state.paymentReducer.tax,
        loading: state.paymentReducer.loadingTax,
        jwt: state.authReducer.jwt,
        user_metadata: state.authReducer.user?.user_metadata,
        saved: state.accountReducer.saved,
      }),
      shallowEqual
    )

  const { data: prices } = useGetPricesQuery()
  const { data: feature } = useCheckIfFeatureIsEnabledQuery({
    feature: 'invoicing',
  })
  const [submitting, setSubmitting] = useState(false)
  const [hasCustomer, setHasCustomer] = useState(false)
  const [price, setPrice] = useState<Price>()

  const findPrice = useCallback(
    (_prices: Price[], _companySector: string, isInvoiceEnabled: boolean) => {
      if (isInvoiceEnabled && _companySector === CompanySector.PRIVATE_EQUITY) {
        setPrice(
          _prices.find(
            price => price.lookup_key === PriceLookupKeys.PRIVEQSTANDARD
          )
        )
      } else {
        setPrice(
          _prices.find(
            price => price.lookup_key === PriceLookupKeys.STANDARD_YEARLY
          )
        )
      }
    },
    []
  )

  useEffect(() => {
    if (!loading && jwt && !tax) {
      dispatch(getTax())
    }
  }, [dispatch, jwt, loading, prices, tax])

  useEffect(() => {
    if (prices && company && company.sector) {
      findPrice(prices, company.sector, feature?.invoicing)
    }
  }, [company, company?.sector, prices, findPrice, feature?.invoicing])

  useEffect(() => {
    if (customer) {
      setHasCustomer(true)
    }
  }, [customer])

  useEffect(() => {
    if (saved && submitting) {
      getAccessTokenSilently().then(token => {
        if (token) {
          dispatch(authActions.setJWT(token))
        }
        window.location.replace('/account-setup')
        setSubmitting(false)
      })
    }
  }, [dispatch, getAccessTokenSilently, saved, submitting])

  const companySize = (company?.size ? +company.size : undefined) as number

  if (!company) {
    return <Redirect to="/hello/2" />
  }

  if (user_metadata?.onboarding?.hidden) {
    return <Redirect to="/account-setup" />
  }

  return (
    <CardForm
      endOnboarding={() =>
        endOnboarding(dispatch, user_metadata, setSubmitting)
      }
      price={price}
      size={companySize}
      hasCustomer={hasCustomer}
      invoicingEnabled={!!feature?.invoicing}
      amount={toAmount({ price, language: i18n.language, size: companySize })}
    />
  )
}

export const PaymentForm = React.memo(PaymentFormComponent)
