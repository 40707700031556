import { emptySplitApi } from 'emptySplitApi'
import { ApiVersionOptions, SessionId } from 'store/apiHeaders/types'
import { Stripe } from 'store/customer/types'

const onboardingApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getActiveSubscription: build.query<boolean, { sessionId: SessionId }>({
      query: ({ sessionId }) => ({
        url: `customer/subscription`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      transformResponse: (response: Stripe) =>
        !!['active', 'trialing'].includes(response?.status),
    }),
    checkIfFeatureIsEnabled: build.query<any, { feature: string }>({
      query: ({ feature }) => ({ url: `features/${feature}` }),
    }),
    checkGoogleUserHasBeenInvited: build.query<boolean, void>({
      query: () => ({ url: `account/users/check-invite` }),
    }),
  }),
})

export const {
  useGetActiveSubscriptionQuery,
  useCheckIfFeatureIsEnabledQuery,
  useCheckGoogleUserHasBeenInvitedQuery,
} = onboardingApi
