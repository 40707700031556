import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Navigate as Redirect } from 'react-router-dom'

import styled from 'styled-components'

import { Text, baseColors } from '@fairhq/common'
import { Fields } from 'components/form/Fields'
import { Form } from 'components/form/Form'

import { useCompanyForm } from '../hooks/useCompanyForm'

const StyledStepDescription = styled(Text)`
  color: ${baseColors.grey400};
  margin-bottom: 24px;
`

const CompanyFormComponent = () => {
  const { t } = useTranslation()
  const [redirect, setRedirect] = useState('')
  const { errorMessage, fields, form, model, setForm, onSubmit, submitting } =
    useCompanyForm(() => {
      setRedirect(`/hello/3`)
    })

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Form
      model={model}
      formValue={form}
      loading={submitting}
      submitBtnLabel={t('hello.companyInfo.continueToPayment')}
      onChange={(formValue: typeof form) => setForm(formValue)}
      onSubmit={onSubmit}
      errorMessage={errorMessage}
      disabled={submitting}
      checkTrigger="change"
      withoutContainer
    >
      <StyledStepDescription element="p" type="body" size="sm" fontWeight={400}>
        {t('hello.companyInfo.description')}
      </StyledStepDescription>

      <Fields data={fields} />
    </Form>
  )
}

export const CompanyForm = React.memo(CompanyFormComponent)
